import React from 'react'

import springsuitcover from './springsuitcover.jpg'
import './App.css'
import { Button } from '@mui/material'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBHYkrCWwNE9jmyfYU4KQJcwgM8ezTMh8U',
  authDomain: 'springsuit-d81df.firebaseapp.com',
  projectId: 'springsuit-d81df',
  storageBucket: 'springsuit-d81df.appspot.com',
  messagingSenderId: '663571407',
  appId: '1:663571407:web:7b37105f75a920c5e260ba',
  measurementId: 'G-XJWP542D3T'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={springsuitcover} className="App-logo" alt="logo" />
        <p>SPRING SUIT</p>

        <LinkCard link="https://springsuit.bandcamp.com/" title="Bandcamp" />
        <LinkCard link="https://open.spotify.com/artist/5Yrtn9lYOMoDA69DGI01Ej" title="Spotify" />
        <LinkCard link="https://music.apple.com/us/artist/spring-suit/1523811521" title="Apple Music" />
        <LinkCard link="https://music.amazon.com/artists/B08D3963JG/spring-suit" title="Amazon Music" />
      </header>
    </div>
  )
}

export default App

function LinkCard({ link, title }: { link: string; title: string }) {
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{ bgcolor: 'white', color: '#000928', width: '90%', my: 1 }}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </Button>
  )
}
